// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/logo.png'; // Make sure to adjust the path to your logo image
import logo_w from '../../assets/logo_w.png'
import './footer.css'; // Import custom CSS for footer styles

const Footer = () => {
  return (
    <footer className="text-white py-4 footer">
      <Container>
        <Row>
          {/* Email Column */}
          <Col md={4} className="mb-3 mb-md-0">
            {/* <h5>Home Of Concrete</h5> */}
            <img
              src={logo_w}
              width="200px"
              height="200px"
              className="d-inline-block align-top"
              alt="Home of Concrete"
            />
          </Col>

          {/* Telephones Column */}
          <Col md={4} className="mb-3 mb-md-0">
            <h5>Telephones</h5>
            <p>01060699067</p>
            <p>01555674589</p> 
          </Col>

          {/* Contact Us Column */}
          <Col md={4}>
            <h5>Contact Us</h5>
            <p>
              <a href="mailto:info@home-of-concrete.com" className="text-white">
                Email us: info@home-of-concrete.com
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="my-0 py-3 center">© 2024 Home Of Concrete , All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
    
  );
};

export default Footer;
