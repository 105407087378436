// src/components/Vision.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import visionImage from '../../assets/Vission.png'; // Replace with your image path

const Vision = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        <Col md={3}>
        <div style={{width: '200px'}}>
        <Image src={visionImage} fluid rounded />
      </div>
          
        </Col>
        <Col md={9} className="mission-vision">
          <h2 className="mb-4">Our Vision</h2>
          <p>
            Our vision is depend on acquired expertise, technical knowledge and the latest scientific researches, in order to achieve and maintain the trust of our clients and provide the required specifications and quality.
            </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Vision;
