// src/components/Service.js
import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import earthworkImage from '../../assets/earthwork.jpg'; // Replace with actual image paths
import concreteImage from '../../assets/concrete.jpg';
import infrastructureImage from '../../assets/infrastructure.jpg';
import landscapeImage from '../../assets/landscape.png';
import finishingImage from '../../assets/Finishing.jpg';

const services = [
  { id: 1, title: 'Earthwork', image: earthworkImage },
  { id: 2, title: 'Construction Concrete',  image: concreteImage },
  // { id: 3, title: 'Infrastructure Network', description: 'Description of Infrastructure Network', image: infrastructureImage },
  //{ id: 4, title: 'Landscape', description: 'Description of Landscape', image: landscapeImage },
  { id: 3, title: 'Landscape & Finishing', image: finishingImage },
];

const Service = () => {
  return (
    <Container className="my-5  service" >  
 
      <h1>{"Services"}</h1> 
      <Row>
        {services.map(service => (
          <Col md={4} className="mb-4" key={service.id}>
            <Link to={`/service/${service.id}`} style={{ textDecoration: 'none' }}>
              <Card className="text-center border-0">
                <Card.Img variant="top" src={service.image} className="rounded" style={{ height: '200px', objectFit: 'cover' }} />
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
 
    </Container>
  );
};

export default Service;
