// src/components/ProjectGallery.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './projectGallary.css'; // Custom CSS for styling

const ProjectGallery = () => {
  const { id } = useParams(); // Retrieve project ID from URL
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  // Dynamically load images from the folder associated with the project
  const images = [
    require(`../../assets/${id}/image1.jpg`).default,
    require(`../../assets/${id}/image2.jpg`).default,
  ];

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };

  return (
    <div className="gallery">
      <h2>{id} Gallery</h2>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            onClick={() => handleShow(image)}
          >
            <img src={image} alt={`Project ${index + 1}`} />
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <img src={selectedImage} alt="Selected" className="img-fluid zoom" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProjectGallery;
