// src/components/Mission.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import missionImage from '../../assets/Mission.png'; // Replace with your image path

const Mission = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        <Col md={3}>
        <div>
          <Image src={missionImage} fluid rounded />
          </div>
        </Col>
        <Col md={9} className="mission-vision">
          <h2 className="mb-4">Our Mission</h2>
          <p>
            Our mission is to become a major partner in the development process of Egypt in the field of contracting.
            their highest potential through technology. We aim to make a significant impact on the global stage by delivering cutting-edge services and products.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Mission;
