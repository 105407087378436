// src/components/ProjectList.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ProjectList.css'; // Custom CSS for styling

const projectFolders = [
  { id: 'Project1', title: 'Building facade design work' },
  { id: 'Project2', title: 'Electrical cables and accessories ' },
  { id: 'Project3', title: 'gypsum board' },
  { id: 'Project4', title: 'PINTERIOR' },
  { id: 'Project5', title: 'MV I CITY NEW CAIRO' },
  { id: 'Project6', title: 'PMV RAS ELHEKMA' },
  { id: 'Project7', title: 'Shingle & Painting Works' }, 
];

const ProjectList = () => {
  return (
    <div className="project-list">
      <h2>Our Projects</h2>
      <div className="list-container">
        {projectFolders.map((project) => (
          <Link
            to={`/project/${project.id}`}
            key={project.id}
            className="project-item"
          >
            <h3>{project.title}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
