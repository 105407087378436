import React from 'react'
import { BannerSlideShow,Mission,Vision,Service,ServiceDetail,Navbar,Footer,ProjectList,ProjectGallery,ScrollToTopButton} from './components'
import {  BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/custom.css'; // Import the custom CSS file
// Importing Bootstrap CSS

const App = () => {
  return (
    
    <Router>
    <Navbar />
    
    <Routes>
      {/* Root page showing all services */}
      <Route path="/" element={<><BannerSlideShow />
      
      <Mission />
      <Vision />
      <Service />  
     
   
      
      </>} />
       {/* Mission page */}
  <Route path="/other/mission" element={<><BannerSlideShow /><Mission /></>} />
  {/* Vision page */}
  <Route path="/other/vision" element={<><BannerSlideShow /><Vision /></>}  />
      {/* Services page */}
      <Route path="/services" element={<><BannerSlideShow /><Service /></>} />
      {/* Dynamic route for service details */}
      <Route path="/service/:id" element={<><BannerSlideShow /><ServiceDetail /></>} />
      {/* Catch-all route for invalid URLs */}
      <Route path="*" element={<p>Page not found. Please select a valid service.</p>} />
      <Route path="/ProjectList" element={<ProjectList />} />
      <Route path="/ProjectGallery/:id" element={<ProjectGallery />} />
    
    </Routes>
    
    <Footer /> {/* Include Footer */}
    <ScrollToTopButton />
  </Router>
);
};

// export const App = () => {
//   const location = useLocation(); // Hook to get the current route

//   return ( 

//     <>
//     <Navbar/>
//     <BannerSlideShow/>
//     <Mission/>
//     <Vision/> 

//       {/* Conditionally render BannerSlideshow only on the home page ("/") */}
//       {location.pathname === '/' && <Mission /> && <Vision/>}

//       <Routes>
//         {/* Root page showing all services */}
//         <Route path="/" element={<Service />} />
//         {/* Dynamic route for service details */}
//         <Route path="/service/:id" element={<ServiceDetail />} />
//         {/* Catch-all route for invalid URLs */}
//         <Route path="*" element={<p>Page not found. Please select a valid service.</p>} />
//       </Routes>
//     </>
//   );
// };

export default App;