import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/logo.png'; // Ensure the path is correct
import { Link } from 'react-router-dom';
import './navbar.css'; // Ensure this file exists and contains necessary styles
import logo_w from '../../assets/logo_w.png'
export default function MyNavbar() {
  return (
    <Navbar className="navbar fixed-top navbar-expand-lg navbar-light bg-light" expand="lg"> {/* `expand="lg"` makes it collapse on smaller screens */}
      <Container> 
        <img className="navbar-brand"
            src={logo}
            width="80px"
            height="85px"        
            // className="d-inline-block align-top"
            alt="Home of Concrete"
          />
     
        <Navbar.Toggle aria-controls="light-navbar-nav bg-light navbar-light" /> {/* This is the toggle button for mobile */}
        <Navbar.Collapse id="collapse navbar-collapse"> {/* Collapsible part for mobile */}
          <Nav className="navbar-nav mr-auto mt-2 mt-lg-0">
            <Nav.Link as={Link} to="/" className='nav-item nav-link'>Home</Nav.Link>
            <Nav.Link as={Link} to="/other/Mission" className='nav-item nav-link'>Mission</Nav.Link>
            <Nav.Link as={Link} to="/other/Vision" className='nav-item nav-link'>Vision</Nav.Link>
            <Nav.Link as={Link} to="/services" className='nav-item nav-link'>Services</Nav.Link>
            {/* <Nav.Link as={Link} to="/ProjectList" className='nav-item nav-link'>Projects</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1" className='nav-item nav-link'>Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className='nav-item nav-link'>Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className='nav-item nav-link'>Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
