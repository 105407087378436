// src/components/BannerSlideShow.js
import React from 'react';
import { Carousel } from 'react-bootstrap';
import banner1 from '../../assets/banner1.jpg';  // Adjust the paths to your banner images
import banner2 from '../../assets/banner2.jpg';
import banner3 from '../../assets/banner3.jpg';
import banner4 from '../../assets/banner4.jpg';
import banner5 from '../../assets/banner5.jpg';

const BannerSlideShow = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner1}
          alt="First banner"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height for banner style
        />
        <Carousel.Caption>
          {/* <h1>Building facade design work</h1> */}
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner2}
          alt="Second banner"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height for banner style
        />
        <Carousel.Caption>
          {/* <h1>Concrete works</h1> */}
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner3}
          alt="Third banner"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height for banner style
        />
        <Carousel.Caption>
          {/* <h1>Interior</h1> */}
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner4}
          alt="Fourth banner"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height for banner style
        />
        <Carousel.Caption>
          {/* <h1>Iron work & Pergolas</h1> */}
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img 
        className="d-block w-100"
        src={banner5}
        alt="Fifth banner"
        style={{height:'500px',objectFit:'cover'}}
        />
        <Carousel.Caption>
            {/* <h1>Wooden works & painting</h1> */}
            <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  );
}

export default BannerSlideShow;
