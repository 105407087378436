import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Earthwork1 from '../../assets/Earthwork1.jpg'; // Replace with actual image paths
import Earthwork2 from '../../assets/Earthwork2.jpg';
import Earthwork3 from '../../assets/Earthwork3.jpg';
import Earthwork4 from '../../assets/Earthwork4.jpg';
import Earthwork5 from '../../assets/Earthwork5.jpg';
import Earthwork6 from '../../assets/Earthwork6.jpg';
import Earthwork7 from '../../assets/Earthwork7.jpg';
import Earthwork8 from '../../assets/Earthwork8.jpg';


import Construction_Concrete1 from '../../assets/Construction_Concrete1.jpg';
import Construction_Concrete2 from '../../assets/Construction_Concrete2.jpg';
import Construction_Concrete3 from '../../assets/Construction_Concrete3.jpg';
import Construction_Concrete4 from '../../assets/Construction_Concrete4.jpg';
import Construction_Concrete5 from '../../assets/Construction_Concrete5.jpg';
import Infrastructure1 from '../../assets/Infrastructure1.jpg';
import Infrastructure2 from '../../assets/Infrastructure2.jpg';
import Infrastructure3 from '../../assets/Infrastructure3.jpg'; 
import Landscape1 from '../../assets/Landscape1.jpg';
import Landscape2 from '../../assets/Landscape2.jpg';
import Landscape3 from '../../assets/Landscape3.jpg';
import Landscape4 from '../../assets/Landscape4.jpg';
import Landscape5 from '../../assets/Landscape5.jpg';
import Landscape6 from '../../assets/Landscape6.jpg';
import Landscape7 from '../../assets/Landscape7.jpg';
import Landscape8 from '../../assets/Landscape8.jpg';
import Landscape9 from '../../assets/Landscape9.jpg';
import Landscape10 from '../../assets/Landscape10.jpg';
import Landscape11 from '../../assets/Landscape11.jpg';
import Landscape12 from '../../assets/Landscape12.jpg';
import Landscape13 from '../../assets/Landscape13.jpg';
import Landscape14 from '../../assets/Landscape14.jpg';
import Landscape15 from '../../assets/Landscape15.jpg';
import Landscape16 from '../../assets/Landscape16.jpg';
import Landscape17 from '../../assets/Landscape17.jpg';

import Finishing1 from '../../assets/Finishing1.jpg';
import Finishing2 from '../../assets/Finishing2.jpg';
import Finishing3 from '../../assets/Finishing3.jpg';
import Finishing4 from '../../assets/Finishing4.jpg';
import Finishing5 from '../../assets/Finishing5.jpg';
import Finishing6 from '../../assets/Finishing6.jpg';
import Finishing7 from '../../assets/Finishing7.jpg';
import Finishing8 from '../../assets/Finishing8.jpg';
import Finishing9 from '../../assets/Finishing9.jpg';
import Finishing10 from '../../assets/Finishing10.jpg';
import Finishing11 from '../../assets/Finishing11.jpg';
import Finishing12 from '../../assets/Finishing12.jpg';
import Finishing13 from '../../assets/Finishing13.jpg';
import Finishing14 from '../../assets/Finishing14.jpg';
import Finishing15 from '../../assets/Finishing15.jpg';
import Finishing16 from '../../assets/Finishing16.jpg';
import Finishing17 from '../../assets/Finishing17.jpg';
import Finishing18 from '../../assets/Finishing18.jpg';
import Finishing19 from '../../assets/Finishing19.jpg';
import Finishing20 from '../../assets/Finishing20.jpg';
import Finishing21 from '../../assets/Finishing21.jpg';
import Finishing22 from '../../assets/Finishing22.jpg';
import Finishing23 from '../../assets/Finishing23.jpg';
import Finishing24 from '../../assets/Finishing24.jpg';
import Finishing25 from '../../assets/Finishing25.jpg';
import Finishing26 from '../../assets/Finishing26.jpg';
import Finishing27 from '../../assets/Finishing27.jpg';
import Finishing28 from '../../assets/Finishing28.jpg';
import Finishing29 from '../../assets/Finishing29.jpg';
import Finishing30 from '../../assets/Finishing30.jpg';
import Finishing31 from '../../assets/Finishing31.jpg';
import Finishing32 from '../../assets/Finishing32.jpg';
import Finishing33 from '../../assets/Finishing33.jpg';
import Finishing34 from '../../assets/Finishing34.jpg';
import Finishing35 from '../../assets/Finishing35.jpg'; 
import Finishing36 from '../../assets/Finishing36.jpg';
import Finishing37 from '../../assets/Finishing37.jpg';
import Finishing38 from '../../assets/Finishing38.jpg';
import Finishing39 from '../../assets/Finishing39.jpg';
import Finishing40 from '../../assets/Finishing40.jpg';
import Finishing41 from '../../assets/Finishing41.jpg';
import Finishing42 from '../../assets/Finishing42.jpg';
import Finishing43 from '../../assets/Finishing43.jpg';
import Finishing44 from '../../assets/Finishing44.jpg';
import Finishing45 from '../../assets/Finishing45.jpg';
import Finishing46 from '../../assets/Finishing46.jpg';
import Finishing47 from '../../assets/Finishing47.jpg';
import Finishing48 from '../../assets/Finishing48.jpg';
import Finishing49 from '../../assets/Finishing49.jpg';
import Finishing50 from '../../assets/Finishing50.jpg';
import Finishing51 from '../../assets/Finishing51.jpg';
import Finishing52 from '../../assets/Finishing52.jpg';
import Finishing53 from '../../assets/Finishing53.jpg';
import Finishing54 from '../../assets/Finishing54.jpg';
import Finishing55 from '../../assets/Finishing55.jpg';
const serviceDetails = {
  1: {
    title: 'Earthwork',
    description: 'Scope of works includes all excavation works to provide the perfect construction base.',
    images: [Earthwork1, Earthwork2,Earthwork3,Earthwork4,Earthwork5,Earthwork6,Earthwork7,Earthwork8]
  },
  2: {
    title: 'Construction Concrete',
    description: 'We offers substantial construction experience , scope of works includes all concrete structure elements that we aim to achieve it efficiently in the shortest time with the experience of our professional team.',
    images: [Construction_Concrete1, Construction_Concrete2, Construction_Concrete3,Construction_Concrete4,Construction_Concrete5]
  },
  // 3: {
  //   title: 'Infrastructure Network',
  //   description: 'Detailed description of Infrastructure Network.',
  //   images: [Infrastructure1, Infrastructure2, Infrastructure3]
  // },
  // 4: {
  //   title: 'Landscape',
  //   description: 'Detailed description of Landscape.',
  //   images: [Landscape1, Landscape2, Landscape3]
  // },
  3: {
    title: 'Landscape & Finishing',
    description: 'We offers substantial architecture experience ,Scope of works includes all architectural finishing for buildings and all landscapes needed for compounds like marble , interlock and soft scope that we do it proffesionally .',
    images: [Landscape1, Landscape2, Landscape3,Landscape4,Landscape5,Landscape6,Landscape7,Landscape8,Landscape9,Landscape10,Landscape11,Landscape12,Landscape13,Landscape14,Landscape15,Landscape16,Landscape17,
      Finishing1, Finishing2, Finishing3,Finishing4,Finishing5,Finishing6,Finishing7,Finishing8,
      Finishing9, Finishing10, Finishing11,Finishing12,Finishing13,Finishing14,Finishing15,Finishing16,
      Finishing17, Finishing18, Finishing19,Finishing20,Finishing21,Finishing22,Finishing23,Finishing24,
      Finishing25, Finishing26, Finishing27,Finishing28,Finishing29,Finishing30,Finishing31,Finishing32,
      Finishing33, Finishing34, Finishing35,Finishing36,Finishing37,Finishing38,Finishing39,Finishing40,
      Finishing41, Finishing42, Finishing43,Finishing44,Finishing45,Finishing46,Finishing47,Finishing48,
      Finishing49, Finishing50, Finishing51,Finishing52,Finishing53,Finishing54,Finishing55
    ]
  }
};

const ServiceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const service = serviceDetails[parseInt(id)];

  // State for managing modal visibility and the selected image
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Handle image click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  // Handle modal close
  const handleClose = () => setShowModal(false);

  // Handle case when no valid service is found
  if (!service) {
    return (
      <Container className="my-5 text-center">
        <h1>Service not found</h1>
        <p>Please select a valid service from the list below.</p>
        <Link to="/">
          <Button variant="primary">Go Back to Services</Button>
        </Link>
      </Container>
    );
  }

  return (
    <Container className="my-5 service">
      {/* Service Title and Description */}
      <h1>{service.title}</h1>
      <p>{service.description}</p>

      {/* Image Gallery */}
      <Row className="mt-4">
        {service.images.map((image, index) => (
          <Col md={4} className="mb-4" key={index}>
            <img
              src={image}
              alt={`Service Detail ${index}`}
              className="img-fluid rounded"
              style={{ height: '250px', objectFit: 'cover', width: '100%', cursor: 'pointer' }}
              onClick={() => handleImageClick(image)} // Add onClick event for image
            />
          </Col>
        ))}
      </Row>

      {/* Modal for large image preview */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Service Preview" style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Back to Home Button */}
      <Button variant="secondary" onClick={() => navigate('/')}>
        Back to Home
      </Button>
    </Container>
  );
};

export default ServiceDetail;
